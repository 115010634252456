;
import { toastConfig } from "react-simple-toasts";
import { getPrepEmail } from "../admin/getPrepEmail";

  export async function reportHandler_admin_email({
    chat_id
   ,chat_text
   ,chatter_name
   ,chatter_id
   ,reporter_name
   ,reporter_id
   ,ssr_data_tribe
 }) {

   const chat_report = 
`Contents:
================================================
${chat_text} 
================================================

[chat by: ${chatter_name} / ${chatter_id}]

[reported by: ${reporter_name} / ${reporter_id}]`

   try {

       await getPrepEmail({
        ssr_data_tribe: ssr_data_tribe
        ,emailto: process.env.NEXT_PUBLIC_EMAIL_SUPPORT
           ,emailtoname: "SUPPORT"
           ,bol_notification_bitarray: "1"
           ,notification_bitarray: 32767
           ,notification_type:'change'
           ,emailfrom: process.env.NEXT_PUBLIC_EMAIL_NOTIFICATIONS
           ,emailfromname: ssr_data_tribe?.brand_name
           ,emailsubject: `Reporting Chat content - ID: ${chat_id}`
           ,emailbodytext: chat_report
           ,emailbodyhtml: chat_report //optional
           ,emailccto: process.env.NEXT_PUBLIC_EMAIL_NOTIFICATIONS //include support to reply
           ,emailcctoname: ssr_data_tribe?.brand_name //include support to reply
          
         })

         toastConfig({
           time: 10000,
           className: '',
           position: 'right'
       });
         //toast(`Report submitted ya snitch.`, { time: 10000, className: '', clickable: true, clickClosable: true })
         
     
   } catch (e) {
     //set_submitting(false)
     throw Error(e.message)
   }

   
 }