import showdown from 'showdown'
import { email, NOTIFICATION_PERMS } from '@/lib/config'
import { getBitwise } from '../bitoperations'



export async function getPrepEmail({
    ssr_data_tribe
    ,emailto        = ''
    ,emailtoname    = ''
    ,bol_notification_bitarray    = "1" //TODO revisit this
    ,notification_bitarray = 32767
    ,notification_type      = 'change'
    ,emailperms     = NOTIFICATION_PERMS().SITE
    ,emailfrom      = process.env.NEXT_PUBLIC_EMAIL_NOTIFICATIONS
    ,emailfromname  = ssr_data_tribe?.brand_name
    ,emailsubject   = ''
    ,emailbodytext  = ''
    ,emailbodyhtml  = ''
    ,emailccto      = ''
    ,emailcctoname  = ''
    ,includeFooter  = true
    
},emailoverride = false) {
    
    var converter = new showdown.Converter()

    const perms_keys = Object.keys(emailperms)

    const jsonbody = JSON.stringify({ 
        emailto        : emailto
       ,emailtoname    : emailtoname
       ,emailfrom      : emailfrom
       ,emailfromname  : emailfromname
       ,emailsubject   : emailsubject
       ,emailbodytext  : converter.makeHtml(`${emailbodytext}${includeFooter ? email(ssr_data_tribe).footer : ''}`)
       ,emailbodyhtml  : converter.makeHtml(`${emailbodyhtml}${includeFooter ? email(ssr_data_tribe).footer : ''}`)
       ,emailccto      : emailccto
       ,emailcctoname  : emailcctoname
    })

    //console.log("/getPrepEmail ",jsonbody)

    if ( bol_notification_bitarray?.toString() == '1' 
        || (bol_notification_bitarray?.toString()== '2' && getBitwise(notification_bitarray,perms_keys)[notification_type]) 
        || emailoverride) {

          //  console.log("in override")

        fetch('/api/private/email/send', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: jsonbody
          });

          //console.log("/getPrepEmail = ok",jsonbody)
        
    //} else {

        //console.log("emailprefs",emailprefs,emailtype)
        //console.log("/getPrepEmail = fail")
    }

    return false

}