
import { ModalContext } from "@/components/context/modal-wrapper";
import { PlayerContext } from "@/components/player/player-wrapper";
import { LoadingProcessing } from "@/components/templateux/loading/loading-processing";
import AuthorCredit from "@/components/templateux/user/author-credit";
import { config } from "@/lib/config";
import { countUpBySeconds } from "@/lib/utils/countUp"
import { getTimestamp } from "@/lib/utils/getTimestamp";
import { hasData } from "@/lib/utils/hasData";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";

import { timerFormat } from "@/lib/utils/timerFormat"
import Link from "next/link";
import { useContext, useEffect,  useState } from "react";


export default function PostTimestamp({
    file
    ,duration
    ,post_id
    ,content_id
    ,content_table
    ,profiledata
    ,userdata
    ,session
    ,owner_id
    ,owner_name
    ,avatar_url
    ,broadcast_title
    ,start_date
    ,router
}) { 

    const [isMounted,set_isMounted] = useState(false)
    useEffect(()=>{set_isMounted(true)},[])

    const {isOpen,set_isOpen} = useContext(ModalContext)

    const {player_state,set_player_state} = useContext(PlayerContext)

    const [cache,set_cache] = useState('')

    // const [active_sentence,set_active_sentence] = useState({seek:0,index:`0-0`})

    //SWR to retrieve all chat messages here
    const { 
        data: timestamparraydata
      , loading: timestamparraydataisLoading
      , error: timestamparraydataisError
      , boundMutate: itemsMutate
                      } = useSWRWrapper(
                        post_id?.toString() //id
                        ,`/api/private/timestamp/select?id=${post_id}&cache=${cache}` //key
                        ,true //retry?
                        ,config.max_retries //max number of retries
                        ,config.retry_interval //retry interval
                        ,0  //refreshinterval (0=none)
                        )   
                    

    const startPlayerTimestamp = async (timestamp) => {

       
       
       
        await set_player_state({});

    

        let player_object = {...player_state
        ,file:`${file}`
        ,start_seek: getTimestamp(timestamp) !==0 
                    ? timestamp 
                    : 0
        ,seek: getTimestamp(timestamp) !==0 
                ? timestamp 
                : 0
        ,seek_ratio: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,seek_slider: getTimestamp(timestamp) !==0 
                        ? (timestamp * 100) / duration 
                        : 0
        ,post_id: post_id
        ,content_id: content_id
        ,content_table: content_table
        ,user_id: owner_id
        ,user_name: owner_name
        ,player_type: 'file'
        ,title: broadcast_title
        ,duration: duration
        ,stream_start_date: start_date
        ,start_date: start_date
        ,isPlaying:true
        ,isMuted:false
        ,isEnded:false
        ,avatar_url: avatar_url
        ,isHide: "0"
        }

        // console.log("player_object",player_object,display_data)
        

        await set_player_state(player_object);

        //return false;

    }


    // {JSON.stringify(timestamparraydata)}
    

    if (timestamparraydataisLoading) {
        return (<> <div className="p-10">
                                <LoadingProcessing />
                        </div></>)
    }

    if (!timestamparraydataisLoading) {

        


        return <>
                    <div className="">

                        {timestamparraydata.length == 0 &&
                         <div className="text-center py-10 italic text-gray-200">
                        No timestamps to show.
                        </div>
                        }
                        {timestamparraydata.length > 0 && 
                            timestamparraydata?.map((ts,index) => {


                            return (
                                <div 
                                    className="font-normal flex items-top content-start px-4" 
                                    key={index}
                                >
                                    
                                            <div className={`${index > 0 ? 'border-t border-gray-700' : ''} pr-2 text-blue-400 underline hover:no-underline cursor-pointer  pt-3`}
                                            
                                                onClick={()=>{ 
                                                    startPlayerTimestamp(ts.duration)
                                                }}
                                            >
                                            {timerFormat(countUpBySeconds(Math.round(ts.duration)))}
                                            </div>
                                            <div 
                                                className={`flex-1 text-left py-4 ${index > 0 ? 'border-t border-gray-700' : ''}`}
                                            
                                            >
                                                <div
                                                    key={index}
                                                    className="w-full ">
                                                        <div className="flex items-center content-center w-full px-4 ">
                                                            <div className="mr-2 flex-0 font-bold">
                                                                <Link
                                                                    href={`/${ts.user_name?.toLowerCase()}`}
                                                                >
                                                                    <AuthorCredit
                                                                        display_data={ts}
                                                                        isPlaying={false}
                                                                        profiledata={profiledata}
                                                                    />
                                                                </Link>
                                                            </div>
                                                            
                                                            <div className="flex-1">
                                                                
                                                            </div>
                                                            <div className="flex-0">
                                                                {/* <ChatTimer
                                                                    start_date={+new Date(e?.crdate)}
                                                                    timestamp={+new Date(e?.timestamp)}
                                                                    time={time}
                                                                /> */}
                                                            </div>
                                                        </div>
                                                    
                                                </div>
                                                <div className=" ml-5 ">
                                                    {hasData(ts?.tag_json) &&
                                                    <>
                                                    {JSON.parse(ts?.tag_json)?.map((e,index) => {
                                                        return (<div
                                                            key={index}
                                                            className="flex-0 inline text-2xs bg-gray-600 text-white px-1 py-0.5 rounded-md mr-2"
                                                        >
                                                            {e?.t}
                                                        </div>)
                                                    })}
                                                   
                                                    </>
                                                    }
                                                    {hasData(ts.notes) &&
                                                     <div
                                                        className="flex-1 inline"
                                                    >
                                                        {ts.notes} 
                                                    </div>
                                                    }
                                                   
                                                </div>
                                                
                                            </div>
                                </div>
                            );



                        })}
                    </div>
                               
                   </>;
    }
    
}